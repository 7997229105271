.btn-grey {
    background-color: $colorTundora;
    border: 0 none;
    font-family: 'open_sansregular', Arial, sans-serif;

    &:hover {
        color: $colorWhite;
        background-color: rgba($colorTundora, 0.92);
    }

    &:active, &:focus {
        background-color: rgba($colorTundora, 0.95);
    }
}
