@import "../components/breadcrumbs";
@import "../components/flash-messenger";
@import "../components/menu-bottom";
@import "../components/search";

.landscape {
    background: url('/img/landscape-smaller.jpg') no-repeat scroll center top;
    width: 100%;
    background-size: auto auto;
    position: relative;
    z-index: 0;
    height: 505px;
    margin-bottom: -429px;
}

#main {
    padding-top: 112px;
}

.cookie-policy {
    display: block;
    bottom: 0;
    left: 0;
    padding: 10px 10px;
    position: fixed;
    right: 0;
    text-align: center;
    background-color: #c3c3c3;
    opacity: 0.9;
    z-index: 9999;

    .close-cookie-info {
        margin-left: 10px;
    }
}

.home {
    .landscape {
        background: url('/img/landscape.jpg') no-repeat scroll center top;
        height: 473px;
        margin-bottom: -271px;
    }
}

.no-padding {
    padding: 0;
    width: auto;
    float: left;
}

.margin-bottom20 {
    margin-bottom: 20px;
}

.color-green {
    color: $colorGreen;
}

.btn {
    border-radius: 4px;
}

.btn-lg {
    font-size: 14px;
    line-height: 20px;
}

.paragraph {
    margin: 0 0 10px;
}

@media (max-width: 1600px) {
    #main {
        padding-top: 102px;
    }
}

@media (max-width: 1200px) {
    #main {
        padding-top: 92px;
    }

    .container {
        width: 98%;
        margin-left: 1%;
        margin-right: 1%;
    }

    .landscape {
        background-size: 140% auto;
    }

    .home {
        .landscape {
            background-size: 140% auto;
        }
    }
}

@media (max-width: 995px) {
    .home {
        .landscape {
            margin-bottom: -318px;
        }
    }

    .landscape {
        height: 270px;
        margin-bottom: -220px;
    }
}

@media (max-width: 900px) {
    #main {
        padding-top: 82px;
    }
}

@media (max-width: 840px) {
    .home {
        .landscape {
            margin-bottom: -330px;
        }
    }
}

@media (max-width: 768px) {
    #main {
        padding-top: 85px;
    }

    .landscape {
        background-size: 160% auto;
        margin-bottom: -196px;
    }

    .home {
        .landscape {
            background-size: 160% auto;
            margin-bottom: -306px;
        }
    }
}

@media (max-width: 767px) {
    .landscape {
        background-image: none;
        background-color: $colorWhiteLinen;
    }

    .home {
        .landscape {
            height: 5px;
            margin-bottom: 0;
            background-image: none;
            background-color: $colorWhiteLinen;
        }
    }

    .sub {
        .landscape, .search-container {
            display: none;
        }
    }
}

/** Print */
@media print {
    .header, .landscape, .search-container, .estates-short-list, .social-networks , .dont-print {
        display: none;
    }
}
