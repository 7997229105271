.regulations {
    h2 {
        font-family: 'open_sansregular', Arial, sans-serif;
        font-size: 19px;
        padding: 15px 15px 10px 0;
        color: #022a3b;
        margin: 0;
    }

    ol {
        @media (max-width: 768px) {
            padding-left: 20px;
        }
    }
}

.list-alpha {
    list-style-type: lower-alpha;
}
