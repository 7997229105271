.ad-steps {
    text-align: center;
    margin-bottom: -20px;
}

.nav-pills {
    &.nav-wizard {
        display: inline-block;

        li {
            position: relative;
            overflow: visible;
            border-right: 15px solid $colorWhite;
            border-left: 15px solid $colorWhite;

            &:first-child {
                border-left: 0;

                a {
                    border-radius: 5px 0 0 5px;
                }
            }

            &:last-child {
                border-right: 0;

                a {
                    border-radius: 0 5px 5px 0;
                }
            }

            a {
                border-radius: 0;
                background-color: #eee;
                color: #777777;
                text-transform: none;
            }

            .nav-arrow {
                position: absolute;
                top: 0;
                right: -20px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 20px 0 20px 20px;
                border-color: transparent transparent transparent #eee;
                z-index: 150;
            }

            .nav-wedge {
                position: absolute;
                top: 0;
                left: -20px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 20px 0 20px 20px;
                border-color: #eee #eee #eee transparent;
                z-index: 150;
            }

            &:hover {
                .nav-arrow {
                    border-color: transparent transparent transparent #aaa;
                }

                .nav-wedge {
                    border-color: #aaa #aaa #aaa transparent;
                }

                a {
                    background-color: #aaa;
                    color: $colorWhite;
                }
            }

            &.active {
                .nav-arrow {
                    border-color: transparent transparent transparent $colorGreen;
                }

                .nav-wedge {
                    border-color: $colorGreen $colorGreen $colorGreen transparent;
                }

                a {
                    background-color: $colorGreen;
                }
            }

            &.active, &.disabled {
                a {
                    color: $colorWhite;
                }
            }
        }
    }
}

.nav-pills li a {
    padding: 10px 50px;
}
