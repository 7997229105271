.ad-details {
    margin-bottom: 20px;
}

.details-list {
    border: 1px solid #e3e3e3;
    color: #74777c;
    list-style-type: none;
    padding: 0 10px;
    float: left;

    li {
        border-bottom: 1px solid #e3e3e3;
        height: auto;
        line-height: 24px;
        padding: 9px 0;
        float: left;
        width: 100%;

        &:last-child {
            border-bottom: 0;
        }
        strong {
            text-align: right;
        }
    }
    .li-grey {
        background-color: #f1f3f6;
        margin-left: -10px;
        margin-right: -10px;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: initial;
    }
}

.more-links {
    a {
        margin-bottom: 5px;
    }
}
