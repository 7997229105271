@import "libs/font-awesome.min";
@import "libs/bootstrap.min";
@import "libs/normalize";
@import "libs/awesome-bootstrap-checkbox";

@import "utils/colours";
@import "utils/fonts";
@import "utils/mixins";

@import "layout/footer";
@import "layout/header";
@import "layout/layout";

@import "pages/add-ad";
@import "pages/app";
@import "pages/estates-item";
@import "pages/import-ads";
@import "pages/login";
@import "pages/new-ad";
@import "pages/not-found";
@import "pages/register";
@import "pages/regulations";

@import "components/buttons";
@import "components/forms";
@import "components/loader";
@import "components/paginator";

html, body {
  height: 100%;
}

body {
  margin: 0 auto;
  min-width: 320px;
  max-width: 1920px;
  color: $colorMineShaft;
  background-color: $colorWhiteLinen;
}

h1, h2 {
  font-family: 'open_sansregular', Arial, sans-serif;
  font-size: 21px;
  padding: 20px 15px 15px;
  color: #022a3b;

  @media (max-width: 768px) {
    font-size: 18px;
  }
}

h1.no-padding {
  text-indent: -100000px;
  overflow: hidden;

  @media (max-width: 768px) {
    text-indent: 0;
    float: none;

    span {
      display: none;
    }
  }
}

h3 {
  font-family: 'open_sansregular', Arial, sans-serif;
  font-size: 19px;
  padding: 15px 15px 10px 0;
  color: #022a3b;
  margin-top: 10px;
}

a {
  cursor: pointer;
}

a:link, a:hover, a:active, a:visited {
  outline: 0;
}

a:focus {
  outline: none !important;
}

a[disabled] {
  cursor: not-allowed;
  pointer-events: none;
}

.btn:focus {
  outline: none !important;
}

#stickyLayout {
  clear: both;
  min-height: 100%;
  height: auto !important;
  margin-bottom: -54px;

  #push {
    height: 54px;
  }
}

.container {
  max-width: 1170px;
  background-color: #fff;
}

.content {
  position: relative;
}
