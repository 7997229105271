/** Defined colors: **/
$colorGreen: #009a00;
$colorWhite: #ffffff;
$colorBlack: #000000;
$colorBostonBlue: #428bca;
$colorMineShaft: #1f1f1f;
$colorMercury: #e4e4e4;
$colorDeYork: #78c575;
$colorTundora: #454545;
$colorGallery: #efefef;
$colorWhiteLinen: #f8f0e8;
$colorPromoted: #fbf5d7;
