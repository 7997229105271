@import "../components/estate/estate-details";
@import "../components/estate/additional-information";
@import "../components/estate/gallery-slider";
@import "../components/estate/geo-location";

.ad-details-text {
    font-size: 14px;
    line-height: 26px;
    white-space: pre-line;
    color: #74777c;
    margin-bottom: 20px;
    overflow-x: hidden;
}

.estate-details {
    margin-bottom: 20px;

    .estate-details-properties {
        color: #74777c;
        padding-left: 0;
        list-style-type: none;
        clear: both;

        .glyphicon {
            margin-right: 5px;

            &.glyphicon-ok {
                color: $colorGreen;
            }
            &.glyphicon-remove {
                color: red;
            }
            &.glyphicon-ok, &.glyphicon-remove {
                border: 1px solid #e3e3e3;
                padding: 5px;
                border-radius: 4px;
                margin-bottom: 5px;
            }
        }
    }
}

.social-networks {
    float: right;
    margin: 11px 0 0 0;

    li {
        display: block;
        float: left;
        margin-left: 3px;

        a {
            border: 1px solid #4d4f52;
            border-radius: 4px;
            color: #4d4f52;
            display: inline-block;
            font-size: 14pt;
            height: 32px;
            line-height: 32px;
            opacity: 0.5;
            text-align: center;
            width: 32px;
            float: left;

            &:hover {
                opacity: 1;
            }
        }

        .safe-button {
            color: $colorGreen;

            &.active {
                color: $colorWhite;
                background-color: $colorGreen;
            }
        }
    }
}

.more-ads-city {
    @media (min-width: 768px) and (max-width: 1000px) {
        clear: both;
        float: left !important;
    }

    @media (max-width: 567px) {
        clear: both;
        float: left !important;
    }
}
