.well-container {
    margin: 0 1%;
    float: left;
    width: 98%;
    clear: both;

    h1, h2 {
        border-bottom: 1px solid #eee;
        padding-left: 0;
    }

    .desc-right {
        line-height: 25px;
        padding-top: 10px;

        ul {
            padding-left: 20px;
        }
    }

    .nav-tabs {
        border-bottom: 0;
        margin-bottom: 10px;

        & > li {
            background-color: $colorWhite;
            border-bottom: 1px solid #d5d5d5;

            &.active {
                background-color: #f5f5f5;
                border-right: 1px solid #d5d5d5;
                border-bottom: 0;

                h1, h2 {
                    border-bottom: 1px solid #eee;
                }
            }

            h1, h2 {
                border-bottom: 0;

                a {
                    display: inline-block;
                    width: 100%;
                    color: #022a3b;
                    cursor: pointer;

                    &:hover, &:link, &:visited {
                        text-decoration: none;
                    }
                }
            }
        }

        & > li:last-child {
            &.active {
                border-left: 1px solid #d5d5d5;
                border-right: 0;
            }
        }
    }
}

.well-form {
    border: 1px solid #d5d5d5;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
    box-sizing: border-box;
    background-color: #f5f5f5;
    margin-top: 25px;
    margin-bottom: 15px;
    border-radius: 0;
    padding-bottom: 20px;

    form {
        margin-top: 15px;
    }
}


@media (max-width: 768px) {
    .well-container {
        .nav-tabs {
            margin-left: 0;
            margin-right: 0;
        }
    }
    .well-form {
        padding-left: 0;
        padding-right: 0;
        overflow: hidden;
    }
}

@media (max-width: 495px) {
    .well-container {
        h1, h2 {
            padding: 15px 10px 10px 0;
            font-size: 14px;
        }
    }
    .nav-pills li a {
        padding: 10px 10px;
        font-size: 15px;
        line-height: 20px;
    }
}
