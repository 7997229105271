#ad-gallery {
    margin-bottom: 15px;
    height: 0;
    padding-bottom: 67%;

    a img {
        width: 100%;
        max-width: 100%;
    }

    .carousel-inner {
        cursor: zoom-in;
        height: 0;
        padding-bottom: 66.66%;
    }
}
