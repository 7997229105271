@import "../components/estate/ad-steps";

.photos-uploader {
    h3 {
        margin: 0;
        padding-bottom: 0;
    }

    .drag-drop {
        border: 2px dashed #dedede;
        border-radius: 10px;
        height: 120px;
        width: 100%;
        margin: 5px 0;
        position: relative;
        overflow: hidden;

        .fa {
            color: $colorGreen;
            font-size: 60px;
            left: 50%;
            margin-left: -32px;
            margin-top: -40px;
            position: absolute;
            top: 50%;
        }

        .drag-drop-text {
            position: absolute;
            left: 50%;
            color: $colorGreen;
            font-size: 18px;
            bottom: 7px;
            font-family: 'open_sansregular', Arial, sans-serif;
            margin-left: -48px;
        }
    }
}

.ad-actions {
    font-size: 23px;
    height: 30px;

    .glyphicon {
        margin: 0 3px;
    }
    .glyphicon-exclamation-sign {
        color: red;
        cursor: default;
    }
    .glyphicon-ok-sign {
        color: $colorGreen;
        cursor: default;
    }
    a {
        &:hover, &:active, &:link, &:visited {
            text-decoration: none;
        }
    }

    .promote-photo {
        color: $colorBostonBlue;

        &:hover:before {
            content: "\e006";
        }
    }
}

.ad-stats {
    font-size: 15px;
    margin: 10px 0 5px;
    clear: both;
    float: left;
    width: 100%;
    color: #707070;

    .glyphicon {
        margin: 3px 7px 3px 3px;
        cursor: default;
    }

    > span {
        margin-right: 20px;
    }
}
