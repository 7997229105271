.breadcrumb {
    border-radius: 0;
    width: 98%;
    margin: 5px 1% 0;
    border: 1px solid $colorMercury;
    background-color: $colorGallery;
    padding: 5px 15px;
    font-size: 12px;
}

.breadcrumb > li + li:before {
    content: '> ';
}
