// Mixin to check if it's retina or not
@mixin tr-retina-display() {
    @media
    only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (-o-min-device-pixel-ratio: 3/2),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
        @content;
    }
}

// Set proper background image for retina displays and normal ones
@mixin tr-background-image($name, $spriteNormal, $spriteRetina) {
    background-image: sprite-url($spriteNormal);
    background-position: sprite-position($spriteNormal, $name);
    background-repeat: no-repeat;
    width: image-width(sprite-file($spriteNormal, $name));
    height: image-height(sprite-file($spriteNormal, $name));

    @include tr-retina-display() {
        background-image: sprite-url($spriteRetina);
        @include background-size(image-width(sprite-path($spriteNormal)) image-height(sprite-path($spriteNormal)));

        @if (sprite-position($spriteRetina, $name) != sprite-position($spriteNormal, $name)) {
            $xpos: round(nth(sprite-position($spriteRetina, $name), 1) / 2);
            $ypos: round(nth(sprite-position($spriteRetina, $name), 2) / 2);
            background-position: $xpos $ypos;
        }
    }
}

@mixin transition($value) {
    -webkit-transition: $value;
    -moz-transition: $value;
    -o-transition: $value;
    transition: $value;
}
