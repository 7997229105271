.navbar {
    border-bottom: 0;
    background-color: $colorWhite;
    border-bottom: 1px solid $colorMercury;
    margin-bottom: 0;
}

.navbar-collapse {
    position: relative;
    bottom: -1px;
    float: right;
}

.nav > li > a {
    font-family: 'open_sansregular', Arial, sans-serif;
    text-transform: uppercase;
    padding: 30px 15px;
}

.navbar-default .navbar-nav > li > a {
    color: $colorMineShaft;
}

.header .navbar-default .navbar-nav > li > a:hover,
.header .navbar-default .navbar-nav > li > a:focus {
    color: $colorGreen;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    background-color: transparent;
    color: $colorGreen;
    border-bottom: 3px solid $colorGreen;
}

#logo {
    float: left;
    width: 284px;
    height: 73px;
    margin: 3px 0 4px 0;
    display: inline-block;
    background: url('/img/logo.png') no-repeat;
    background-size: 100% 100%;

    @include tr-retina-display() {
        background: url('/img/logo-x2.png') no-repeat;
        background-size: 100% 100%;
    }
}

.top-add-button {
    margin: 23px 0 0 30px;

    @media (max-width: 1600px) {
        margin-top: 18px;
    }

    @media (max-width: 1200px) {
        margin-top: 13px;
    }

    @media (max-width: 900px) {
        margin-top: 10px;
    }

    @media (max-width: 768px) {
        margin-left: 10px;
    }

    &:hover {
        opacity: 0.92;
    }

    .btn {
        background-color: #656565;
        color: $colorWhite;
    }
}

/** Sticky header **/
.is-sticky {
    border-top: 12px solid $colorGreen;
    opacity: 0.95;

    .top-bar {
        display: none;
    }
}

@media (max-width: 1600px) {
    #logo {
        width: 245px;
        height: 63px;
    }

    .header {
        .nav > li > a {
            padding: 25px 15px;
        }
    }
}

@media (max-width: 1200px) {
    #logo {
        width: 206px;
        height: 53px;
    }

    .header {
        .nav > li > a {
            padding: 20px 15px;
        }
    }

    // Use full width fot nav-tabs
    .nav-full .nav-tabs > li {
        display: table-cell;
        width: 1%;
    }
    .nav-full .nav-tabs {
        width: 100%;
    }
    .nav-full .nav-tabs > li {
        float: none;
    }
    .nav-full .nav-tabs > li > a {
        text-align: center;
    }
    .nav-full .nav-tabs > li {
        display: table-cell;
        width: 1%;
    }
}

@media (max-width: 960px) {
    .header {
        .nav > li > a {
            padding: 20px 10px;
        }
    }
}

@media (max-width: 900px) {
    #logo {
        width: 175px;
        height: 45px;
    }

    .header {
        .nav > li > a {
            padding: 17px 10px;
        }
    }
}

@media (max-width: 768px) {
    #logo {
        width: 167px;
        height: 43px;
        float: none;
    }

    .header {
        .navbar-header {
            text-align: center;
        }

        .navbar-nav {
            float: none !important;
        }
    }
}

@media (max-width: 767px) {
    .header {
        .navbar-collapse {
            float: none;
        }
    }
}
