.ad-details-map {
    clear: both;
    float: left;
    width: 100%;
}

#map {
    width: 100%;
    min-height: 200px;
    padding-bottom: 35%;
    height: 0;
}

#mapContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.google-map {
    padding: 0;
}

.google-street {
    @media (min-width: 768px) {
        padding-right: 0;
    }

    @media (max-width: 767px) {
        margin: 20px 0;
    }

    img {
        max-width: 100%;
        width: 100%;
    }
}

.geo-location {
    @media (max-width: 767px) {
        padding: 0;
    }
}
