@font-face {
    font-family: 'open_sansregular';
    src: url('/fonts/OpenSans-Regular-webfont.eot');
    src: url('/fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('/fonts/OpenSans-Regular-webfont.woff') format('woff'),
    url('/fonts/OpenSans-Regular-webfont.ttf') format('truetype'),
    url('/fonts/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
