.error-h2 {
    margin-top: 20px;
}

.error-404 {
    width: 100%;
    max-width: 512px;
    height: 343px;
    background: url('/img/404.png') no-repeat;
    background-size: 100% auto;
    display: block;
    margin: 30px auto 20%;
}
