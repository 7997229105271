.well-container {
    .nav-tabs {
        & > li {
            width: 50%;
            padding: 0 15px;

            a, a:hover, a:focus {
                background-color: white;
                border: 0;
                text-transform: none;
                padding: 0;
            }

            &.active {
                border-right: 1px solid #d5d5d5;

                a {
                    background-color: #f5f5f5;
                }
            }
        }
    }
}

.register-contact {
    clear: both;
    margin-top: 30px;
    width: 100%;
    float: left;
}
