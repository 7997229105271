.software-list {
    background-color: #efefef;
    border: 1px solid #e4e4e4;
    float: left;
    list-style-type: none;
    margin: 0 1% 20px;
    padding: 15px;
    width: 98%;

    .software-logo {
        float: right;
        margin: 0 0 20px 10px;
        width: 250px;

        @media (max-width: 500px) {
            width: 100%;
            margin: 10px 0 10px;
        }

        a {
            display: block;
            width: 100%;
            float: left;
        }
        img {
            width: 100%;
            max-width: 100%;
        }
    }
}
