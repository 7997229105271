$stickyFooter: 54px;

footer {
    background-color: $colorMercury;
    height: $stickyFooter;
    line-height: $stickyFooter;
    font-family: 'open_sansregular', Arial, sans-serif;
    font-size: 12px;
    color: #74777c;
    clear: both;
    position: relative;

    .container {
        background-color: $colorMercury;
    }

    .social-networks {
        @media (max-width: 768px) {
            display: none;
        }
    }
}

.share-wraper {
    border: 1px solid #e3e3e3;
    margin-bottom: 15px;
    padding: 5px 5px;
    float: left;
    width: 100%;

    ul {
        padding-left: 0;
        margin-top: 0;
        width: 100%;
    }
}

.social-networks {
    float: right;
    margin: 11px 0 0 0;

    li {
        display: block;
        float: left;
        margin-left: 3px;

        a {
            border: 1px solid #4d4f52;
            border-radius: 4px;
            color: #4d4f52;
            display: inline-block;
            font-size: 14pt;
            height: 32px;
            line-height: 32px;
            opacity: 0.5;
            text-align: center;
            width: 32px;
            float: left;

            &:hover {
                opacity: 1;
            }
        }

        .safe-button {
            color: $colorGreen;

            &.active {
                color: $colorWhite;
                background-color: $colorGreen;
            }
        }
    }
}

.share-wraper {
    border: 1px solid #e3e3e3;
    margin-bottom: 15px;
    padding: 5px 5px;
    float: left;
    width: 100%;

    ul {
        padding-left: 0;
        margin-top: 0;
        width: 100%;
    }
}

@media print {
    .social-networks {
        display: none;
    }
}
