.bottom-links {
    background-color: #e4e4e4;
    border: 1px solid #e3e3e3;
    margin: 0 1% 10px;
    width: 98%;
    padding: 8px 0;
    font-size: 13px;

    a {
        margin: 0 10px;
        color: #74777c;
        border-right: 1px solid #BABCC1;
        padding-right: 23px;

        &:last-child {
            border-right: 0;
            padding-right: 0;
        }
    }

    @media (max-width: 767px) {
        display: none;
    }
}
