.form-group {
    margin-right: 0;

    @media (max-width: 767px) {
        margin-right: 10px;
    }

    ul {
        list-style-type: none;
        margin-top: 5px;
        color: red;
        padding-left: 0;
        text-align: center;
    }
}

.form-label {
    width: 100%;

    @media (max-width: 767px) {
        display: none;
    }
}

.form-label-asterisk {
    color: red;
}

.form-asterisk {
    color: red;
    width: 10px;
    text-align: right;
    float: right;
    display: none;

    @media (max-width: 767px) {
        display: inline-block;
    }
}

.form-tooltip {
    float: right;
    color: #3030e0;
}

.form-control:focus, .form-control:link {
    box-shadow: none;
    border-color: #ccc;
}

@media (max-width: 768px) {
    input[required].form-control, textarea[required].form-control {
        width: calc(100% - 10px);
    }
}

input[type=checkbox] {
    cursor: pointer;
}

input[type=checkbox].form-regulations {
    float: left;
    margin: 11px 10px 11px 0;
}

input[type=checkbox].form-login {
    float: left;
    margin: 4px 10px 11px 0;
}

textarea {
    resize: none;
    min-height: 200px;
}

.form-group.checkbox {
    margin-top: 0;
}

.checkbox input[type="checkbox"]:focus + label::before,
.checkbox input[type="radio"]:focus + label::before {
    outline: 0;
}

.has-error .form-control {
    border: 1px solid red;
}

.captcha-error {
    margin-left: 10px;
    color: red;
}
