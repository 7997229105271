.new-ad {
    .type-container {
        margin: 30px 0;
        padding: 0;
        border: 2px solid;
        background-color: $colorWhite;

        .type-link {
            margin: 5px;
            display: block;
            text-align: center;
            font-size: 30px;
            line-height: 150px;
            color: white;
            font-family: 'open_sansregular', Arial, sans-serif;

            &:hover, &:active, &:link, &:visited {
                text-decoration: none;
            }
        }
    }

    #apartment {
        border-color: #039041;

        .type-link {
            background-color: #039041;
        }
    }

    #house {
        border-color: #57a0cb;

        .type-link {
            background-color: #57a0cb;
        }
    }

    #land {
        border-color: #5da738;

        .type-link {
            background-color: #5da738;
        }
    }

    #place {
        border-color: #74adc1;

        .type-link {
            background-color: #74adc1;
        }
    }

    #object {
        border-color: #76be52;

        .type-link {
            background-color: #76be52;
        }
    }
}
